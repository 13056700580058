//
//  src/layouts/depict/marketing/unsubscribe/Unsubscribe.js
//
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Nav from "../../../../components/nav/Nav"
import Footer from "../../../../components/footer/Footer"
import M from "materialize-css/dist/js/materialize.min.js";
import './Unsubscribe.css'


//
//  marketing unsubscribe
//
const MarketingUnsubscribe = () => {
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const emailParam = params.get('email');
        if (emailParam) {
            setEmail(emailParam);
        }
    }, [location.search]);

    useEffect(() => {
        if (email) {
            M.updateTextFields();
        }
    }, [email]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            console.log(email)
            const response = await fetch(`${process.env.REACT_APP_API_BASE}/guest/marketing`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'email': email,
                    'marketing': false
                })
            });

            if (response.ok) {
                setSuccess(true);
                M.toast({ html: 'You have unsubscribed from marketing emails', classes: 'green' });
            } else {
                M.toast({ html: 'Error unsubscribing. Please try again.', classes: 'red' });
            }
        } catch (error) {
            M.toast({ html: 'Network error. Please try again.', classes: 'red' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div id="marketing-unsubscribe">
            <Nav />
            <div className="container">
                <main>
                    <h1>Marketing Unsubscribe</h1>
                </main>
                <section>
                    <div>
                        {!success ? (
                            <>
                                <p className="info-text">
                                    Enter your email address below to update your preferences and unsubscribe from our marketing emails.
                                </p>
                                <br />
                                <form onSubmit={handleSubmit}>
                                    <div className="input-field">
                                        <input
                                            id="email"
                                            type="email"
                                            className="validate"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                            style={{ fontSize: '20px', padding: '16px', width: 'calc(100% - 32px)' }}
                                        />
                                        <label htmlFor="email">Email</label>
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn waves-effect waves-light"
                                        disabled={loading}
                                        style={{ fontSize: '14px', width: '100%', height: '64px', backgroundColor: 'rgb(255, 152, 0)' }}
                                    >
                                        {loading ? 'Processing...' : 'Unsubscribe'}
                                    </button>
                                </form>
                                <br />
                                <div className="row">
                                    <p className="info-text info-text-bottom">
                                        Once you submit your email, your unsubscribe request will be processed and you will be removed from our marketing communications list. We appreciate your trust in us and respect your preferences.
                                    </p>
                                </div>
                                <div className="row">
                                    <p className="waiver">
                                        By submitting this form, you confirm that you have read, understood, and agreed to our <a href="/legal/terms-and-conditions/" alt="terms and conditions">Terms and Conditions</a> and <a href="/legal/privacy-policy/" alt="privacy policy">Privacy Policy</a>.
                                    </p>
                                </div>
                            </>
                        ) : (
                            <>
                                <p className="info-text">
                                    Thank you for taking the time to review your email preferences.
                                </p>
                                <br />
                                <div className="card-panel green lighten-4">
                                    <span className="green-text text-darken-4">
                                        You have unsubscribed from marketing emails.
                                    </span>
                                </div>
                                <br />
                                <p className="info-text info-text-bottom">
                                    Your unsubscribe request has been processed successfully. If you have any further questions or need additional assistance, please feel free to reach out to our team at <a href="mailto:jeff@jefftattooer.com" aria-label="Send email to Jeff Tattooer">jeff@jefftattooer.com</a>.
                                </p>
                            </>
                        )}
                    </div>
                </section>
            </div>
            <Footer />
        </div>


    );
};

export default MarketingUnsubscribe;

